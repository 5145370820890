<template>
    <div  v-if="canAccess('origen_matriculas_read')" class="p-grid">
        <div class="p-col-12">
            <div class="card card-w-title">
                <ProgressBar mode="indeterminate" v-if="processing"/>
                <DataTable :value="items" :filters="filters" class="p-datatable-responsive"
                           v-model:selection="currentItem" selectionMode="single" dataKey="id" :paginator="true"
                           paginatorPosition="bottom" :rows="10">
                    <template #header>
                        <div class="table-header">
                            <h5 class="p-m-0">Gestionar origen de matrícula</h5>
                            <div>
                                 <span class="p-input-icon-left p-ml-3">
                                    <i class="pi pi-search"/>
                                    <InputText v-model="filters['global']" placeholder="Search"/>
                                 </span>
                                <Button   v-if="canAccess('origen_matriculas_create')" label="Agregar" icon="pi pi-plus" class="p-button-success p-ml-2" @click="openNew"/>
                            </div>
                        </div>
                    </template>

                    <Column field="id" header="Codigo" :sortable="true"></Column>
                    <Column field="description" header="Nombre" :sortable="true"></Column>
                    <Column>
                        <template #body="slotProps">
                            <Button   v-if="canAccess('origen_matriculas_update')" icon="pi pi-pencil" class="p-button-rounded p-button-success p-mr-2"
                                    @click="edit(slotProps.data)"/>
                            <Button   v-if="canAccess('origen_matriculas_delete')" icon="pi pi-trash" class="p-button-rounded p-button-warning"
                                    @click="confirmDelete(slotProps.data)" />
                        </template>
                    </Column>
                </DataTable>
                <Dialog   v-if="canAccess('origen_matriculas_update')|| canAccess('origen_matriculas_create')" v-model:visible="formDialog" :style="{width: '450px'}" header="Origen de matrícula" :modal="true"
                        class="p-fluid">
                    <div class="p-field" v-if="currentItem">
                        <label for="description">Nombre</label>
                        <InputText id="description" v-model.trim="currentItem.description" required="true" autofocus
                                   :class="{'p-invalid': submitted && (!currentItem.description)}"/>
                        <small class="p-invalid" v-if="submitted && !currentItem.description">La descripción es requerida</small>
                    </div>
                    <template #footer>
                        <Button label="Cancel" icon="pi pi-times" class="p-button-text" @click="hideDialog"/>
                        <Button label="Save" icon="pi pi-check" class="p-button-text" @click="saveItem"/>
                    </template>
                </Dialog>

                <Dialog   v-if="canAccess('origen_matriculas_delete')" v-model:visible="confirmDeleteModal" :style="{width: '450px'}" header="Confirm" :modal="true">
                    <div class="confirmation-content">
                        <i class="pi pi-exclamation-triangle p-mr-3" style="font-size: 2rem"/>
                        <span v-if="currentItem">Seguro de eliminar registro?</span>
                    </div>
                    <template #footer>
                        <Button label="No" icon="pi pi-times" class="p-button-text"
                                @click="confirmDeleteModal = false"/>
                        <Button label="Si" icon="pi pi-check" class="p-button-text" @click="deleteRow"/>
                    </template>
                </Dialog>
            </div>
        </div>
    </div>
  <div v-else>
    <h5 class="card-header text-center"><b>ATENCIÓN!!</b></h5>
    <div class="card-body">
      <p class="card-title">El acceso a esta opción no se encuentra autorizado</p>
      <Button  label="Regresar" class="p-button-danger p-mr-2"
               @click="$router.push('/admin')"/>
    </div>

  </div>

</template>

<script>
    import service from '../../service/catalog.service'
    import {mapState} from "vuex";
    import bouncer from "../../../../helpers/bouncer";

    export default {
      mixins:[bouncer],
        name: 'RegistrationOrigin',
        data() {
            return {
                path: 'registration/origins',
                confirmDeleteModal: false,
                processing: false,
                submitted: false,
                formDialog: false,
                filters: {},
                items: null,
                currentItem: null,
                layout: 'list',
            }
        },
        carService: null,
        eventService: null,
        nodeService: null,
        created() {

        },
        mounted() {
        if(this.canAccess('origen_matriculas_read')){
          this.getData()
        }
        },
        methods: {
            saveItem() {
                this.submitted = true;
                if(!this.currentItem.description || this.currentItem.description === '')
                {
                    return;
                }
                this.processing = true;
                this.formDialog = false;
                service.save(this.path, this.currentItem, this.currentItem ? this.currentItem.id: undefined).
                then(() => {
                    this.getData();
                    this.$toast.add({severity:'success', summary: 'Registro guardado', detail:'Se guardó correctamente', life: this.$utils.toastLifeTime()});

                }).catch((x) => {
                    const message = x.response.data;
                    let error = '';
                    Object.keys(message).forEach(key => {
                        error += error +  message[key] && message[key][0] ? message[key][0] + '\n' : '';
                    });
                    this.$toast.add({severity:'error', summary: 'Error', detail: error, life: this.$utils.toastLifeTime()});
                }).finally(() => {
                    this.submitted = false;
                    this.processing = false;
                });
            },
            hideDialog() {
                this.submitted = false;
                this.currentItem = {};
                this.formDialog = false;
            },
            openNew() {
                this.submitted = false;
                this.currentItem = {};
                this.formDialog = true;
            },
            getData() {
                this.processing = true;
                service.get(this.path).then(x => {
                    this.items = x.data;
                }).catch(() => {
                    this.$toast.add({severity:'error', summary: 'Error', detail:'se a producido un error', life: this.$utils.toastLifeTime()});
                }).finally(() => {
                    this.processing = false;
                })
            },
            confirmDelete(data) {
                this.currentItem = data;
                this.confirmDeleteModal = true;
            },
            edit(item) {
                this.currentItem = item;
                this.submitted = false;
                this.formDialog = true;

            },
            deleteRow () {
                this.processing = true;
                this.confirmDeleteModal = false;
                service.delete(this.path, this.currentItem.id).then(() => {
                    this.currentItem = {};
                    this.$toast.add({severity:'success', summary: 'Registro eliminado', detail:'Se eliminó correctamente', life: this.$utils.toastLifeTime()});

                    this.getData();
                }).finally(() => {
                    this.processing = false;
                });
            }
        },
        computed: {
            ...mapState('auth', ['isAdmin']),
        }
    }
</script>

<style scoped>
    .table-header {
        display: flex;
        align-items: center;
        justify-content: space-between;
    }

    .confirmation-content {
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .p-progressbar {
        height: 1px;
    }
</style>
